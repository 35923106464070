import { gql } from 'graphql-request';
import { IMAGE_FRAGMENT, NEWS_FRAGMENT, PAGE_FRAGMENT } from './fragments';

export const GET_PAGE_URLS_QUERY = gql`
  query getPages {
    pages {
      data {
        attributes {
          url
        }
      }
    }
  }
`;

export const GET_PAGE_BY_ID_QUERY = gql`
  query getPageById($id: ID!) {
    page(id: $id) {
      data {
        ...PageFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
`;

export const GET_PAGE_BY_URL_QUERY = gql`
  query getPageByUrl(
    $url: String!
    $locale: I18NLocaleCode
    $publicationState: PublicationState
  ) {
    pages(
      publicationState: $publicationState
      locale: $locale
      filters: { url: { eq: $url } }
    ) {
      data {
        ...PageFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
`;

export const GET_SETTINGS = gql`
  query getSettings($locale: I18NLocaleCode) {
    setting(locale: $locale) {
      data {
        attributes {
          Footer {
            address
            socialLinks {
              id
              image {
                data {
                  ...ImageFragment
                }
              }
              url
            }
          }
          texts {
            read
            newsTitle
            noNews
            loadingNews
            placeholderContactEmail
            placeholderContactName
            placeholderContactSubject
            placeholderContactMessage
            labelContactMessage
            valueContactSend
          }
          GoogleAnalytics {
            trackingId
            consentInfo
            acceptAllLabel
            declineAllLabel
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const GET_NEWSLIST = gql`
  query getNewsList($locale: I18NLocaleCode, $page: Int!, $pageSize: Int = 10) {
    newsList(
      locale: $locale
      sort: ["publishDate:desc", "title:asc"]
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          title
          intro
          slug
          locale
          image {
            data {
              ...ImageFragment
            }
          }
          publishDate
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const GET_NEWS = gql`
  query getNews(
    $slug: String!
    $locale: I18NLocaleCode
    $publicationState: PublicationState
  ) {
    newsList(
      locale: $locale
      publicationState: $publicationState
      filters: { slug: { eq: $slug } }
    ) {
      data {
        ...NewsFragment
      }
    }
  }

  ${NEWS_FRAGMENT}
`;

export const GET_NEWS_BY_ID = gql`
  query getNewsById($id: ID!, $locale: I18NLocaleCode) {
    news(id: $id, locale: $locale) {
      data {
        ...NewsFragment
      }
    }
  }

  ${NEWS_FRAGMENT}
`;
