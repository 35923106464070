import { getConfig } from '@lib/config';
import { ImageData } from '@lib/types';

export function buildImageUrl(url: string) {
  if (url.startsWith('http')) {
    return url;
  }

  return `${getConfig().MEDIA_URL}${url}`;
}

export function buildSrcSet(src: ImageData) {
  return [
    `${buildImageUrl(src.url)} ${src.width}w`,
    `${buildImageUrl(src.thumbnail.url)} ${src.thumbnail.width}w`,
    src.small ? `${buildImageUrl(src.small.url)} ${src.small.width}w` : null,
    src.medium ? `${buildImageUrl(src.medium.url)} ${src.medium.width}w` : null,
    src.large ? `${buildImageUrl(src.large.url)} ${src.large.width}w` : null,
    src.xlarge ? `${buildImageUrl(src.xlarge.url)} ${src.xlarge.width}w` : null,
    src.xxlarge
      ? `${buildImageUrl(src.xxlarge.url)} ${src.xxlarge.width}w`
      : null,
  ]
    .filter(Boolean)
    .join(', ');
}

export function fixImageUrl(html: string) {
  return html?.replace(/src="([^"]+)"/g, (match, url) => {
    return `src="${buildImageUrl(url)}"`;
  });
}

export const placeholderImage = {
  id: -1,
  alternativeText: 'Placeholder',
  thumbnail: {
    width: 156,
    height: 156,
    url: '/images/placeholder_thumb.png',
  },
  small: {
    width: 500,
    height: 500,
    url: '/images/placeholder_small.png',
  },
  medium: {
    width: 750,
    height: 750,
    url: '/images/placeholder_medium.png',
  },
  large: {
    width: 1000,
    height: 1000,
    url: '/images/placeholder_large.png',
  },
  hash: '123456',
  ext: 'png',
  mime: 'image/png',
  name: 'placeholder.png',
  width: 1920,
  height: 1920,
  url: '/images/placeholder.png',
};
