module.exports = {
  locales: ['en-US', 'en', 'fr', 'de'],
  defaultLocale: 'en',
  localesLabel: {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    'en-US': 'English (US)',
  },
};
