import { useEffect, useState, useRef, MutableRefObject } from 'react';

export function useOnScreen(ref: MutableRefObject<HTMLElement | null>) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [enterCount, setEnterCount] = useState<number>(0);
  const [leaveCount, setLeaveCount] = useState<number>(0);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
      if (!isOnScreen && entry.isIntersecting) {
        setEnterCount((count) => count + 1);
      }
      if (isOnScreen && !entry.isIntersecting) {
        setLeaveCount((count) => count + 1);
      }
    });
  }, [isOnScreen]);

  useEffect(() => {
    if (ref.current) {
      observerRef.current?.observe(ref.current);
    }
    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return [isOnScreen, enterCount, leaveCount];
}
