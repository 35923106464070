import { gql } from 'graphql-request';

const CURRENT_DATE = new Date().toJSON().slice(0, 10);

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on UploadFileEntity {
    id
    attributes {
      alternativeText
      ext
      formats
      hash
      mime
      name
      width
      height
      url
    }
  }
`;

export const LINK_FRAGMENT = gql`
  fragment LinkFragment on ComponentElementsLink {
    id
    text
    url
  }
`;

export const SEO_FRAGMENT = gql`
  fragment SeoFragment on ComponentSharedSeo {
    metaTitle
    metaDescription
    metaImage {
      data {
        ...ImageFragment
      }
    }
    metaSocial {
      socialNetwork
      title
      description
      image {
        data {
          ...ImageFragment
        }
      }
    }
    keywords
    metaRobots
    structuredData
    metaViewport
    canonicalURL
  }
`;

export const PAGE_FRAGMENT = gql`
  fragment PageFragment on PageEntity {
    id
    attributes {
      publishedAt
      title
      seo {
        ...SeoFragment
      }
      url
      sections {
        __typename
        ... on ComponentSectionsRichText {
          id
          optionalTitle: title
          content
        }
        ... on ComponentSectionsImageText {
          id
          image {
            data {
              ...ImageFragment
            }
          }
          imageTextTitle: title
          subTitle
          link {
            ...LinkFragment
          }
          layout
          imageTextParagraph: paragraph
        }
        ... on ComponentSectionsImage {
          id
          image {
            data {
              ...ImageFragment
            }
          }
        }
        ... on ComponentSectionsVideo {
          id
          video
        }
        ... on ComponentSectionsCards {
          id
          elements {
            overline
            title
            content
            link {
              ...LinkFragment
            }
          }
        }
        ... on ComponentSectionsHero {
          id
          title
          backgroundFocalpoint
          customFocalpoint
          overlineHero: overline
          cover {
            data {
              ...ImageFragment
            }
          }
        }
        ... on ComponentSectionsQuote {
          id
          title
          author
          authorDescription
        }
        ... on ComponentSectionsTitleParagraphLinks {
          id
          title
          paragraph
          links {
            ...LinkFragment
          }
        }
        ... on ComponentSectionsTeam {
          id
          title
          lead
          employeeCategories {
            data {
              id
              attributes {
                title
                employees {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      middleName
                      titlesSalutation
                      suffix
                      description
                      portrait {
                        data {
                          ...ImageFragment
                        }
                      }
                      bio
                      linkedIn
                    }
                  }
                }
              }
            }
          }
        }
        ... on ComponentSectionsPageTitle {
          id
          title
        }
        ... on ComponentSectionsContact {
          id
          title
          paragraph
          successMsg
          errorMsg
          showBg
          function {
            name
          }
          surgicalSpecialty {
            name
          }
        }
        ... on ComponentSectionsEvents {
          id
          title
          paragraph
          numberOfEventsToDisplay
          eventCategories {
            data {
              id
              attributes {
                title
                events(
                  sort: ["sticky:desc", "end:asc"]
                  filters: {
                    or: [
                      { sticky: { eq: true } }
                      {
                        and: [
                          { sticky: { eq: false } }
                          { end: { gt: "${CURRENT_DATE}" } }
                        ]
                      }
                    ]
                  }
                ) {
                  data {
                    id
                    attributes {
                      title
                      start
                      end
                      sticky
                      description
                      location
                    }
                  }
                }
              }
            }
          }
        }
        ... on ComponentSectionsButton {
          buttonLinks: links {
            ...LinkFragment
          }
        }
        ... on ComponentSectionsVideoText {
          id
          vimeoUrl
          videoPreset
          videoTextTitle: title
          videoTextSubTitle: subTitle
          videoTextLink: link {
            ...LinkFragment
          }
          videoTextLayout: layout
          videoTextTextParagraph: paragraph
          videoStartAtSecond: videoStartAtSecond
        }
        ... on ComponentSectionsHeroVideo {
          id
          titleHeroVideo: title
          overlineHeroVideo: overline
          vimeoUrl
          heroVideoStartAtSecond: videoStartAtSecond
        }
        ... on ComponentSectionsVimeo {
          id
          vimeoVimeoUrl: vimeoUrl
          vimeoVideoPreset: videoPreset
          vimeoVideoStartAtSecond: videoStartAtSecond
        }
        ... on ComponentSectionsHighlight {
          id
          text
        }
        ... on ComponentSectionsHtml {
          id
          html
        }
      }
      slug
      locale
    }
  }
  ${IMAGE_FRAGMENT}
  ${LINK_FRAGMENT}
  ${SEO_FRAGMENT}
`;

export const NEWS_FRAGMENT = gql`
  fragment NewsFragment on NewsEntity {
    id
    attributes {
      title
      intro
      publishedAt
      slug
      image {
        data {
          ...ImageFragment
        }
      }
      locale
      publishDate
      seo {
        ...SeoFragment
      }
      content {
        __typename
        ... on ComponentSectionsRichText {
          id
          optionalTitle: title
          content
        }
        ... on ComponentSectionsImageText {
          id
          image {
            data {
              ...ImageFragment
            }
          }
          imageTextTitle: title
          subTitle
          link {
            ...LinkFragment
          }
          layout
          imageTextParagraph: paragraph
        }
        ... on ComponentSectionsImage {
          id
          image {
            data {
              ...ImageFragment
            }
          }
        }
        ... on ComponentSectionsVideo {
          id
          video
        }
        ... on ComponentSectionsCards {
          id
          elements {
            overline
            title
            content
            link {
              ...LinkFragment
            }
          }
        }
        ... on ComponentSectionsHero {
          id
          title
          cover {
            data {
              ...ImageFragment
            }
          }
        }
        ... on ComponentSectionsQuote {
          id
          title
          author
          authorDescription
        }
        ... on ComponentSectionsTitleParagraphLinks {
          id
          title
          paragraph
          links {
            ...LinkFragment
          }
        }
        ... on ComponentSectionsButton {
          buttonLinks: links {
            ...LinkFragment
          }
        }
        ... on ComponentSectionsVideoText {
          id
          vimeoUrl
          videoPreset
          videoTextTitle: title
          videoTextSubTitle: subTitle
          videoTextLink: link {
            ...LinkFragment
          }
          videoTextLayout: layout
          videoTextTextParagraph: paragraph
          videoStartAtSecond: videoStartAtSecond
        }
        ... on ComponentSectionsVimeo {
          id
          vimeoVimeoUrl: vimeoUrl
          vimeoVideoPreset: videoPreset
          vimeoVideoStartAtSecond: videoStartAtSecond
        }
        ... on ComponentSectionsHighlight {
          id
          text
        }
        ... on ComponentSectionsHtml {
          id
          html
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${LINK_FRAGMENT}
  ${SEO_FRAGMENT}
`;
