import i18nConfig from '../i18n';
import { getConfig } from './config';
import graphQLClient from './graphql/graphql-client';
import {
  GET_NEWS,
  GET_NEWSLIST,
  GET_NEWS_BY_ID,
  GET_PAGE_URLS_QUERY,
  GET_PAGE_BY_ID_QUERY,
  GET_PAGE_BY_URL_QUERY,
  GET_SETTINGS,
} from './graphql/queries';
import {
  parseNews,
  parseNewsList,
  parseMenu,
  parsePage,
  parseSettings,
} from './parse';
import { News, Menu, Page, MetaPagination } from './types';

const parseLocale = (l?: string): string => {
  if (!l) return '';
  return l.replace('-us', '-US');
};

export async function getPageUrls(): Promise<string[]> {
  const { pages } = await graphQLClient.request(GET_PAGE_URLS_QUERY, {});

  return pages.data.map((page: any) => page.attributes.url);
}

export async function getPageByUrl(
  url: string,
  locale?: string,
  isPreview: boolean = false,
) {
  const { pages } = await graphQLClient.request(GET_PAGE_BY_URL_QUERY, {
    url,
    locale: parseLocale(locale),
    publicationState: isPreview ? 'PREVIEW' : 'LIVE',
  });

  if (pages.data.length === 0) {
    return null;
  }

  return await parsePage(pages.data[0]);
}

export async function getPageById(id: string): Promise<Page | null> {
  if (!id) {
    return null;
  }

  const { page } = await graphQLClient.request(GET_PAGE_BY_ID_QUERY, {
    id,
  });

  if (!page) {
    return null;
  }

  return await parsePage(page.data);
}

export async function getMenu(
  name: 'main' | 'footer' | 'footer-secondary',
  locale?: string,
): Promise<Menu> {
  if (!locale) {
    locale = i18nConfig.defaultLocale;
  }

  const response = await fetch(
    `${getConfig().MEDIA_URL}/api/menus/?filters[slug][$eq]=${name}${
      locale !== i18nConfig.defaultLocale ? `-${parseLocale(locale)}` : ''
    }&nested&populate=*`,
  );

  const data = await response.json();

  return parseMenu(data);
}

export async function getSettings(locale?: string): Promise<any> {
  const { setting } = await graphQLClient.request(GET_SETTINGS, {
    locale: parseLocale(locale),
  });

  if (setting.data.length === 0) {
    return null;
  }

  return parseSettings(setting.data);
}

export async function getNewsList(
  locale?: string,
  page: number = 1,
  pageSize: number = 10,
): Promise<{
  news: News[];
  meta: {
    pagination: MetaPagination;
  };
}> {
  const { newsList } = await graphQLClient.request(GET_NEWSLIST, {
    locale,
    page,
    pageSize,
  });

  if (newsList.data.length === 0) {
    return { news: [], meta: newsList.meta };
  }

  return { news: parseNewsList(newsList.data), meta: newsList.meta };
}

export async function getNews(
  slug: string[],
  isPreview: boolean,
  locale?: string,
): Promise<News | null> {
  const { newsList } = await graphQLClient.request(GET_NEWS, {
    slug,
    locale: parseLocale(locale),
    publicationState: isPreview ? 'PREVIEW' : 'LIVE',
  });

  if (newsList.data.length == 0) {
    return null;
  }

  return parseNews(newsList.data[0]);
}

export async function getNewsById(
  id: string,
  locale?: string,
): Promise<News | null> {
  const { news } = await graphQLClient.request(GET_NEWS_BY_ID, { id });

  if (!news) {
    return null;
  }

  return await parseNews(news.data);
}
