import { getConfig } from '../config';
import { GraphQLClient } from 'graphql-request';

const endpoint = `${getConfig().CMS_URL}/graphql`;

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {},
});

export default graphQLClient;
