import { useOnScreen } from 'hook/useOnScreen';
import { useEffect, useMemo, useRef } from 'react';
import Player from '@vimeo/player';

export const vimeoPresets = {
  default: {
    playOn: 'click',
    params: {
      autoplay: '0',
      loop: '0',
      byline: '0',
      controls: '1',
      muted: '0',
      pip: '1',
      playsinline: '1',
      portrait: '0',
      title: '1',
      color: '59BAC9',
    },
  },
  controls_autoplay: {
    playOn: 'scroll',
    params: {
      autoplay: '0',
      loop: '1',
      byline: '0',
      controls: '1',
      muted: '1',
      pip: '1',
      playsinline: '1',
      portrait: '0',
      title: '1',
      color: '59BAC9',
    },
  },
  no_controls_autoplay: {
    playOn: 'scroll',
    params: {
      autoplay: '0',
      loop: '1',
      byline: '0',
      controls: '0',
      muted: '1',
      pip: '0',
      playsinline: '1',
      portrait: '0',
      title: '0',
      color: '59BAC9',
    },
  },
};

export type VimeoProps = {
  url: string;
  preset?: keyof typeof vimeoPresets | null;
  start?: number | null;
};

export default function Vimeo({ url, preset, start = 0 }: VimeoProps) {
  const settings = useMemo(() => {
    return preset ? vimeoPresets[preset] : vimeoPresets.default;
  }, [preset]);

  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const [isOnScreen] = useOnScreen(videoRef);

  useEffect(() => {
    if (videoRef.current && settings.playOn === 'scroll') {
      const player = new Player(videoRef.current);
      player.getPaused().then((paused) => {
        if (isOnScreen) {
          if (paused) player.play();
        } else {
          if (!paused) player.pause();
        }
      });
    }
  }, [isOnScreen, videoRef, settings.playOn]);

  const src = useMemo(() => {
    // see: https://regexr.com/68gk3
    const id = url.match(
      /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
    );

    const srcUrl = `https://player.vimeo.com/video/${id && id[1] ? id[1] : ''}`;
    const params = new URLSearchParams(settings.params).toString();
    const fragments = start ? `t=${start}s` : '';

    return `${srcUrl}?${params}#${fragments}`;
  }, [url, settings.params, start]);

  return (
    <div className="aspect-w-16 aspect-h-9 w-full relative">
      <iframe
        ref={videoRef}
        className="absolute w-full h-full"
        src={src}
        allow="fullscreen; picture-in-picture; accelerometer; autoplay; encrypted-media; gyroscope;"
        allowFullScreen
        frameBorder={0}
        data-ready="true"
      />
    </div>
  );
}
