import { Favicon } from '@components/favicon';
import { getMenu, getSettings } from '@lib/cms-client';
import { Menu, Settings } from '@lib/types';
import localFont from '@next/font/local';
import classNames from 'classnames';
import { NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import '../styles/globals.css';
import { NextRouter } from 'next/router';

const maderaFont = localFont({
  src: [
    {
      path: './fonts/5617375/a9bc0475-3334-48b6-8035-79e9f611850e.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/5617387/feb17db2-bbcf-4c4d-b394-b99a25ecda0b.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  variable: '--font-madera',
});

interface MyAppProps extends AppProps {
  menu: Menu;
  menuFooter: Menu;
  menuFooterSecondary: Menu;
  settings: Settings;
}

export default function App({
  Component,
  pageProps,
  menu,
  menuFooter,
  menuFooterSecondary,
  settings,
}: MyAppProps) {
  return (
    <>
      <Favicon />
      <div className={classNames(maderaFont.variable, 'h-screen font-sans')}>
        <Component
          {...pageProps}
          menu={menu}
          menuFooter={menuFooter}
          menuFooterSecondary={menuFooterSecondary}
          settings={settings}
        />
      </div>
      <Script
        id="font-tracking"
        async
        dangerouslySetInnerHTML={{
          __html: `
    var MTUserId='15e3cab7-9ab1-4b81-b2b9-d03c8c90e59e';
    var MTFontIds = new Array();

    MTFontIds.push("5617375"); // Madera® W04 Bold
    MTFontIds.push("5617387"); // Madera® W04 Regular

    (function() {
        var mtTracking = document.createElement('script');
        mtTracking.type='text/javascript';
        mtTracking.async='true';
        mtTracking.src='/mtiFontTrackingCode.js';

        (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild(mtTracking);
    })();`,
        }}
      />
      <Script
        id="pardot-tracking"
        dangerouslySetInnerHTML={{
          __html: `
            piAId = '1068393';
            piCId = '149196';
            piHostname = 'go.distalmotion.com';
            (function() {
            function async_load(){
            var s = document.createElement('script'); s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + piHostname + '/pd.js';
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            }
            if(window.attachEvent) { window.attachEvent('onload', async_load); }
            else { window.addEventListener('load', async_load, false); }
            })();`,
        }}
      />
    </>
  );
}

App.getInitialProps = async ({
  ctx,
  router,
}: {
  ctx: NextPageContext;
  router: NextRouter;
}) => {
  const locale = ctx.locale || router.defaultLocale;

  const menu = await getMenu('main', locale);
  const menuFooter = await getMenu('footer', locale);
  const menuFooterSecondary = await getMenu('footer-secondary', locale);
  const settings = await getSettings(locale);
  const pageProps = {};

  return { menu, menuFooter, menuFooterSecondary, pageProps, settings };
};
